import React, {useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

const NavigationBar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
      console.log("User logged out successfully");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-title">
          <img src="/RJD-logo.svg" alt="Logo" className="logo" />
        </Link>
        <div className="navbar-links">
          <Link to="/" className="navbar-button">Home</Link>
          <Link to="/about" className="navbar-button">About</Link>
          <Link to="/services" className="navbar-button">Services</Link>
          <Link to="/contact" className="navbar-button">Contact</Link>
          {user && (
            <button onClick={handleLogout} className="navbar-button">
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
