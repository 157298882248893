import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => (
  <div className="container">
    <h1 className="title">Welcome to Robert Josef Domogalla!</h1>
    <div className="paper">
      <p className="subtitle">Welcome to my website, where I share and upload interesting and funny projects I work on. My goal is to learn as much as possible and make my work accessible to everyone so that I can receive valuable feedback. I am currently studying Computational and Data Science. To support myself, I work part-time as a Java Developer in the core team of an E-Commerce company. I've always thought it would be cool to have my own website to share interesting projects and experiment with new ideas.</p>
    </div>
    <div className="grid">
      <div className="grid-item">
          <h3>Tic-Tac-Toe</h3>
          <p>Play with your friends.</p>
          <Link to="/tic-tac-toe" className="button">Play</Link>
      </div>
      <div className="grid-item">
          <h3>Parallel Computing</h3>
          <p>Information on Parallel Computing from the lectures I attended.</p>
          <Link to="/parallel-computing" className="button">Learn More</Link>
      </div>
      <div className="grid-item">
          <h3>Numerical Math</h3>
          <p>Information on Numerical Math from the lectures I attended.</p>
          <Link to="/numerical-math" className="button">Learn More</Link>
      </div>
      <div className="grid-item">
          <h3>Visualisierung</h3>
          <p>Questions for Exam</p>
          <Link to="/visualisierung" className="button">Learn More</Link>
      </div>
    </div>
  </div>
);

export default HomePage;
