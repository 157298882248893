import React, { useState } from 'react';
import questions from '../../data/questionDataVisu';

const VisualisierungPage = () => {
  
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showAllQuestions, setShowAllQuestions] = useState(true);

  const handleNextQuestion = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * questions.length);
    } while (nextIndex === currentQuestionIndex);
    setCurrentQuestionIndex(nextIndex);
    setShowAnswer(false);
  };

  const handleToggleMode = (mode) => {
    setShowAllQuestions(mode === 'all');
    setCurrentQuestionIndex(0);
    setShowAnswer(false);
  };

  return (
    <div className="container">
      <h1 className="title">Visualization</h1>
      <div className="mode-buttons">
        <button
          className={`button ${showAllQuestions ? 'active' : ''}`}
          onClick={() => handleToggleMode('all')}
        >
          All Questions Mode
        </button>
        <button
          className={`button ${!showAllQuestions ? 'active' : ''}`}
          onClick={() => handleToggleMode('single')}
        >
          Single Question Mode
        </button>
      </div>
      {showAllQuestions ? (
        <div className="paper">
          <section className="section">
            <h2 className="section-title">Introduction</h2>
            <p className="section-content">On this page, you will find some questions and answers for the exam in the course Visualization. The questions are based on the content of the lectures and the exercises.</p>
          </section>
          <section className="section">
            <h2 className="section-title">Questions</h2>
            <ul className="list">
              {questions.map((q, index) => (
                <React.Fragment key={index}>
                  <li className="list-item">
                    <strong>{q.question}</strong>
                    <div>{q.answer}</div>
                    {q.QuestionImageUrl && <img src={q.QuestionImageUrl} alt={q.question} className="question-image" />}
                    {q.AnswerImageUrl && <img src={q.AnswerImageUrl} alt={`Answer for ${q.question}`} className="question-image" />}
                  </li>
                  {index < questions.length - 1 && <hr />}
                </React.Fragment>
              ))}
            </ul>
          </section>
        </div>
      ) : (
        <div className="paper single-question">
          <section className="section">
            <div className="question-header">
              <h2 className="section-title">Question {currentQuestionIndex + 1}</h2>
              <button className="button" onClick={() => setShowAnswer((prevShowAnswer) => !prevShowAnswer)}>
                {showAnswer ? 'Hide Answer' : 'Show Answer'}
              </button>
              <button className="button" onClick={handleNextQuestion}>Next Question</button>
            </div>
            <div className="question-content">
              <strong>{questions[currentQuestionIndex].question}</strong>
              {showAnswer && (
                <div>{questions[currentQuestionIndex].answer}</div>
              )}
              {questions[currentQuestionIndex].QuestionImageUrl && (
                <img
                  src={questions[currentQuestionIndex].QuestionImageUrl}
                  alt={questions[currentQuestionIndex].question}
                  className="question-image"
                />
              )}
              {showAnswer && questions[currentQuestionIndex].AnswerImageUrl && (
                <img
                  src={questions[currentQuestionIndex].AnswerImageUrl}
                  alt={`Answer for ${questions[currentQuestionIndex].question}`}
                  className="question-image"
                />
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default VisualisierungPage;
