import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavigationBar from './components/layout/NavigationBar';
import Footer from './components/layout/Footer';
import HomePage from './components/pages/HomePage';
import TicTacToePage from './components/pages/TicTacToePage';
import ParallelComputingPage from './components/pages/ParallelComputingPage';
import NumericalMathPage from './components/pages/NumericalMathPage';
import VisualisierungPage from './components/pages/VisualisierungPage'; // Import der Visualisierungsseite
import SignIn from './components/auth/SignIn'; // Import der SignIn-Seite
import PrivateRoute from './PrivateRoute'; // Import der PrivateRoute
import './main.scss';
import { Analytics } from "@vercel/analytics/react";


function App() {
  return (
    <div>
      <div>
        <Router>
          <NavigationBar />
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
            <Route path="/tic-tac-toe" element={<PrivateRoute><TicTacToePage /></PrivateRoute>} />
            <Route path="/parallel-computing" element={<PrivateRoute><ParallelComputingPage /></PrivateRoute>} />
            <Route path="/numerical-math" element={<PrivateRoute><NumericalMathPage /></PrivateRoute>} />
            <Route path="/visualisierung" element={<PrivateRoute><VisualisierungPage /></PrivateRoute>} />
            <Route path="*" element={<Navigate to="/signin" />} />
          </Routes>
          <Footer />
        </Router>
      </div>
      <Analytics />
    </div>
  );
}




export default App;
