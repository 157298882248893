import React from 'react';

const NumericalMathPage = () => (
  <div className="container">
    <h1 className="title">Numerical Math</h1>
    <div className="paper">
      <p className="subtitle">Information on Numerical Math from the lectures I attended.</p>
      
      <section className="section">
        <h2 className="section-title">Introduction</h2>
        <p className="section-content">Numerical mathematics is the study of algorithms that use numerical approximation for the problems of mathematical analysis. This includes various methods to solve equations, integrate functions, and find eigenvalues of matrices.</p>
      </section>

      <section className="section">
        <h2 className="section-title">Key Concepts</h2>
        <ul className="list">
          <li className="list-item">Numerical Integration</li>
          <li className="list-item">Numerical Differentiation</li>
          <li className="list-item">Solving Linear Systems</li>
          <li className="list-item">Interpolation and Extrapolation</li>
          <li className="list-item">Eigenvalues and Eigenvectors</li>
        </ul>
      </section>

      <section className="section">
        <h2 className="section-title">Applications</h2>
        <p className="section-content">Numerical methods are widely used in various fields such as engineering, physics, finance, and data science. They are essential for simulating real-world phenomena, optimizing complex systems, and analyzing large datasets.</p>
      </section>

      <section className="section">
        <h2 className="section-title">Resources</h2>
        <ul className="list">
          <li className="list-item"><a href="https://www.example.com/numerical-methods" className="resource-link">Introduction to Numerical Methods</a></li>
          <li className="list-item"><a href="https://www.example.com/numerical-recipes" className="resource-link">Numerical Recipes</a></li>
          <li className="list-item"><a href="https://www.example.com/online-courses" className="resource-link">Online Courses on Numerical Analysis</a></li>
        </ul>
      </section>

      <section className="section">
        <h2 className="section-title">Interactive Example</h2>
        <p className="section-content">Below is an interactive example of solving a linear system of equations using the Gauss-Seidel method.</p>
        <div className="interactive-example">
          {/* Insert interactive component or external tool link here */}
        </div>
      </section>
    </div>
  </div>
);

export default NumericalMathPage;
