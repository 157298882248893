import React from 'react';

function Copyright() {
  return (
    <p className="footer-copyright">
      {'Copyright © '}
      <a href="https://www.robertjosefdomogalla.com" className="footer-link">
        www.robertjosefdomogalla.de
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </p>
  );
}

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <p className="footer-text">Robert Josef Domogalla</p>
        <Copyright />
      </div>
    </footer>
  );
}
