// Komponente für die Anmeldung
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function Copyright() {
  return (
    <p className="footer-copyright">
      {'Copyright © '}
      <a href="https://www.robertjosefdomogalla.de" className="footer-link">
        robertjosefdomogalla.de
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </p>
  );
}

const SignInForm = ({ handleSubmit }) => (
  <form className="sign-in-form" onSubmit={handleSubmit} noValidate>
    <div className="input-container">
      <label htmlFor="email">Email Address</label>
      <input
        type="email"
        id="email"
        name="email"
        required
        autoComplete="email"
        autoFocus
        className="input"
      />
    </div>
    <div className="input-container">
      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        name="password"
        required
        autoComplete="current-password"
        className="input"
      />
    </div>
    <div className="form-control">
      <input type="checkbox" id="remember" name="remember" value="remember" />
      <label htmlFor="remember">Remember me</label>
    </div>
    <button type="submit" className="button" style={{ width: '100%' }}>
      Sign In
    </button>
    <div className="grid-container">
      <div className="grid-item">
        <a href="#" className="link">Forgot password?</a>
      </div>
      <div className="grid-item">
        <a href="#" className="link">{"Don't have an account? Sign Up"}</a>
      </div>
    </div>
  </form>
);

export default function SignIn() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/'); // Wenn der Benutzer eingeloggt ist, leite zur Homepage um
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  if (user) {
    return null; // Return null while we are redirecting
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in successfully');
      navigate('/');
    } catch (error) {
      console.error('Error signing in', error);
      alert('Failed to sign in. Please check your email and password.');
    }
  };

  return (
  <div>
    <div className="sign-in-page">
      <div className="sign-in-container">
        <div className="avatar">
          <span className="avatar-icon">🔒</span>
        </div>
        <h1 className="sign-in-title">Sign in</h1>
        <SignInForm handleSubmit={handleSubmit} />
        <Copyright />
      </div>
    </div>
  </div>
  );
}
