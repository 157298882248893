import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

const codeStringMPI = `MPI_Init(&argc, &argv);
MPI_Comm_rank(MPI_COMM_WORLD, &rank);
MPI_Comm_size(MPI_COMM_WORLD, &size);
if (rank == 0) {
    // master process
} else {
    // worker process
}
MPI_Finalize();
`;

const codeStringOpenMP = `#pragma omp parallel for
for (int i = 0; i < n; i++) {
    // do work in parallel
}`;

const codeStringPython = `# Python code for parallel computing using multiprocessing library
import multiprocessing

def square(n):
    return n * n

if __name__ == '__main__':
    numbers = [1, 2, 3, 4, 5]
    pool = multiprocessing.Pool()
    results = pool.map(square, numbers)
    print(results)

Output: [1, 4, 9, 16, 25]`;

const ParallelComputingPage = () => (
  <div className="container">
    <h1 className="title">Parallel Computing</h1>
    <div className="paper">
      <section className="section">
        <h2 className="section-title">Introduction</h2>
        <p className="section-content">On this side, I will concentrate on the learning I had during my lectures at the university. Most import in Parallele Computing was Openmp and MPI. For both Programming Paradigmen I will show the key Concepts and some example codes. Also I will show some example , where we have used it.</p>
      </section>
      <section className="section">
        <h2 className="section-title">Key Concepts</h2>
        <p className="section-content">Before talking about the two paradigmen, want to make some more details about the fundamentals of parallel Computing. Parallel computing is a type of computation in which many calculations or processes are carried out simultaneously. This is in contrast to serial computing, where calculations are performed sequentially. Parallel computing can be achieved using multiple processors, cores, or nodes, which work together to solve a problem more quickly and efficiently than a single processor could.</p>
        <p className="section-content">It is important to understand the difference between concurrency and parallelism. Concurrency refers to the ability of a system to handle multiple tasks at the same time, while parallelism refers to the simultaneous execution of multiple tasks. Parallel computing can take advantage of both concurrency and parallelism to speed up computations and improve performance.</p>
        <p className="section-content">Additionale Distributed Systems are a key concept in parallel computing. Distributed systems are a collection of independent computers that work together to solve a problem. They are often used in large-scale applications that require high performance and fault tolerance. Distributed systems can be implemented using various architectures, such as client-server, peer-to-peer, and grid computing.</p>
        <p className="section-content">Multithreading is another important concept in parallel computing. Multithreading allows multiple threads to run concurrently within a single process. This can improve performance by taking advantage of multiple cores or processors. Synchronization is also crucial in parallel computing to ensure that threads or processes do not interfere with each other's operations.</p>
        <p className="section-content">Load balancing is a technique used in parallel computing to distribute the workload evenly across multiple processors or nodes. This helps to maximize the utilization of resources and improve the overall performance of the system.</p>
      </section>
      <section className="section">
        <h2 className="section-title">Applications</h2>
        <p className="section-content">Parallel computing is widely used in various fields such as scientific computing, data analysis, artificial intelligence, and real-time systems. It helps in speeding up computations and solving large-scale problems efficiently.</p>
        <p className="section-content">Some common applications of parallel computing include:</p>
        <ul className="list">
          <li className="list-item">Numerical simulations</li>
          <li className="list-item">Image and signal processing</li>
          <li className="list-item">Machine learning and deep learning</li>
          <li className="list-item">Weather forecasting</li>
          <li className="list-item">Financial modeling</li>
          <li className="list-item">Bioinformatics</li>
        </ul>
      </section>
      <section className="section">
        <h2 className="section-title">Parallel Programming Paradigms</h2>
        <p className="section-content">There are several parallel programming paradigms that can be used to implement parallel algorithms and applications. Some of the common paradigms include:</p>
        <ul className="list">
          <li className="list-item">Shared-memory parallelism: In this paradigm, multiple threads or processes share a common memory space and communicate through shared variables. Examples include OpenMP and Pthreads.</li>
          <li className="list-item">Message passing: In this paradigm, processes communicate by sending and receiving messages. Examples include MPI (Message Passing Interface) and PVM (Parallel Virtual Machine).</li>
          <li className="list-item">Data parallelism: In this paradigm, the same operation is performed on multiple data elements simultaneously. Examples include SIMD (Single Instruction, Multiple Data) and GPU programming.</li>
          <li className="list-item">Task parallelism: In this paradigm, tasks are divided into independent units of work that can be executed in parallel. Examples include task-based parallelism and work-stealing algorithms.</li>
        </ul>
      </section>
      <section className="section">
        <h2 className="section-title">OpenMP</h2>
        <p className="section-content">OpenMP (Open Multi-Processing) is an API that supports shared-memory parallel programming in C, C++, and Fortran. It allows developers to parallelize their code by adding compiler directives that specify how the work should be divided among multiple threads.</p>
        <p className="section-content">Some key features of OpenMP include:</p>
        <ul className="list">
          <li className="list-item">Easy to use: OpenMP directives are simple and can be added to existing code with minimal changes.</li>
          <li className="list-item">Portable: OpenMP is supported by most compilers and can be used on a wide range of platforms.</li>
          <li className="list-item">Scalable: OpenMP programs can scale to take advantage of multiple cores or processors.</li>
          <li className="list-item">Flexible: OpenMP supports different levels of parallelism, such as task parallelism and loop parallelism.</li>
        </ul> 
        <p className="section-content">Example code using OpenMP:</p>
        <SyntaxHighlighter language="cpp" style={tomorrow}>
          {codeStringOpenMP}
        </SyntaxHighlighter>
      </section>
      <section className="section">
        <h2 className="section-title">MPI</h2>
        <p className="section-content">MPI (Message Passing Interface) is a standard for message-passing parallel programming in distributed-memory systems. It allows multiple processes to communicate by sending and receiving messages, making it suitable for parallel computing on clusters or supercomputers.</p>
        <p className="section-content">Some key features of MPI include:</p>
        <ul className="list">
          <li className="list-item">Scalable: MPI programs can scale to run on thousands of processors or nodes.</li>
          <li className="list-item">Portable: MPI is supported by many implementations and can be used on various platforms.</li>
          <li className="list-item">Flexible: MPI supports point-to-point communication, collective operations, and derived data types.</li>
          <li className="list-item">Robust: MPI provides error handling and fault tolerance mechanisms for reliable parallel computing.</li>
        </ul>
        <p className="section-content">Example code using MPI:</p>
        <SyntaxHighlighter language="cpp" style={tomorrow}>
          {codeStringMPI}
        </SyntaxHighlighter>
      </section>
      <section className="section">
        <h2 className="section-title">Resources</h2>
        <ul className="list">
          <li className="list-item"><a href="https://www.mcs.anl.gov/research/projects/mpi/" className="resource-link">MPI Official Documentation</a></li>
          <li className="list-item"><a href="https://www.openmp.org/specifications/" className="resource-link">OpenMP Specifications</a></li>
          <li className="list-item"><a href="https://hpc.nih.gov/" className="resource-link">High-Performance Computing Resources</a></li>
        </ul>
      </section>
      <section className="section">
        <h2 className="section-title">Interactive Example</h2>
        <p className="section-content">Below is an interactive example demonstrating the implementation of parallel algorithms using Python's multiprocessing library.</p>
        <SyntaxHighlighter language="cpp" style={tomorrow}>
          {codeStringPython}
          </SyntaxHighlighter>
      </section>
    </div>
  </div>
);

export default ParallelComputingPage;
