const questions = [
  {
      question: 'What is data visualization?',
      answer: (
          <p>Data visualization is the process of representing data in graphical formats such as charts, maps, and graphs. It helps in recognizing and understanding complex data patterns.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Why is data visualization important?',
      answer: (
          <p>Data visualization allows complex datasets to be represented in a simple and intuitive way. It helps in identifying trends and patterns that might otherwise be overlooked and supports decision-making. Statistical measures may be not enough for an understanding of data, e.g., data points may have the same statistical measures, but differ.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What tools are used for data visualization?',
      answer: (
          <p>There are many tools for data visualization, including Matplotlib, Seaborn, Tableau, Power BI, and D3.js. Each of these tools offers different features and is suitable for various use cases.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What are the best practices for data visualization?',
      answer: (
          <p>Best practices for data visualization include choosing the right type of chart, using clear and concise titles and labels, avoiding clutter, and ensuring the accuracy and integrity of the presented data.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Determine Tufte‘s Lie Factor!',
      answer: (
          <p>Lie Factor = (size of effect shown in graphic) / (size of effect in data)</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What is change blindness?',
      answer: (
          <p>Change blindness is a perceptual phenomenon that occurs when a change in a visual stimulus is introduced but not noticed by the observer. This can happen even when the change is significant and should be easily detected.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'State two goals of visualization!',
      answer: (
          <>
              <p>Visualization is good for visual exploration, which involves finding unknown or unexpected insights and generating new hypotheses. It is also useful for visual analysis, also known as confirmative visualization, where it helps to confirm or reject hypotheses and perform detailed information drill-down. Additionally, visualization is crucial for presentation, enabling the effective and efficient communication of results.</p>
              <p>Depending on the state of knowledge about the data, visualization serves different purposes:</p>
              <ul>
                  <li>When nothing is known about the data, visualization helps in visual exploration.</li>
                  <li>When there are hypotheses, visualization aids in visual analysis.</li>
                  <li>When everything is known, visualization is used for presentation.</li>
              </ul>
          </>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Complete the pipeline!',
      answer: (
          <>
              <p>The visualization pipeline involves several stages and can originate from various sources such as simulations, databases, and sensors, collectively referred to as data acquisition. The pipeline begins with raw data, which is the unprocessed input from these sources. This raw data undergoes filtering or enhancement to improve quality and relevance. Following this, the visualization data, which includes layout, color, and size, is prepared. This step is crucial for setting the stage for effective visual representation.</p>
              <p>Next, the visualization data is mapped through a process known as visualization mapping, where the data is transformed into a format suitable for rendering. The renderable representation is the next step in the pipeline, where the data is converted into a form that can be visually presented. Finally, the rendering process produces the visual output, such as images or videos.</p>
              <p>Throughout this pipeline, interaction plays a critical role, allowing users to manipulate and explore the data at various stages, from filtering and enhancement to the final rendering. This interactive aspect helps in refining the visualization and deriving meaningful insights.</p>
              <p>Possible questions related to this pipeline could include tasks such as completing the visualization pipeline by describing each stage and its significance.</p>
          </>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/pipeline.png'
  },
  {
      question: 'State the three pillars of the visualization pipeline!',
      answer: (
          <>
              <p>1. Data generation/acquisition:</p>
              <ul>
                  <li>Measuring, simulation, modeling.</li>
                  <li>Can take very long (measuring, simulation).</li>
                  <li>Can be very expensive (simulation, modeling).</li>
              </ul>
              <p>2. Visualization (rest of the visualization pipeline):</p>
              <ul>
                  <li>Data filtering/enhancement, visualization mapping, rendering.</li>
                  <li>Depends on computer/implementation: fast or slow.</li>
              </ul>
              <p>3. Interaction (user input):</p>
              <ul>
                  <li>How can users change parameters, viewpoint, etc.</li>
              </ul>
          </>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What does this map show us?',
      answer: (
          <>
              <p>Map of the cholera outbreak</p>
              <p>Black boxes represents deaths</p>
              <p>Conclusion: the pump may be the source for the deaths</p>
          </>
      ),
      QuestionImageUrl: 'images/mapOf.png',
      AnswerImageUrl: null
  },
  {
      question: 'State three different human memory types!',
      answer: (
          <p>Sensory memory (iconic memory, Short term memory, Long term memory</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What are the properties of the short term memory?',
      answer: (
          <p>Limited storage capacity, Is conscious, Involves attentive process of perception</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What is the Craik O‘Brien Cornsweet Illusion?',
      answer: (
          <p>The Cornsweet illusion is an optical illusion that involves the perception of brightness and contrast. It occurs when a gradient is placed next to a sharp edge, creating the illusion of a sharp change in brightness or contrast. In reality, the gradient is smooth and continuous, but the sharp edge creates the perception of a sudden change. This effect can be used to enhance the perception of depth and shape in visualizations.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/cornsweetIllusion.png'
  },
  {
      question: 'What is the Weber Fechner Law?',
      answer: (
          <p>Psychophysical relationship in sensory physiology, Linear increase in the (psychically) subjectively perceived strength of sensory impressions corresponds to the logarithm of the increase in the (physically) objectively measurable intensity of the stimulus</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Name five methods to support depth perception!',
      answer: (
          <p>Shadow projection, Illumination effects, Stereo rendering, Perspective, Attenuation of distant portions (depth of field blur), Occlusions should be obvious</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Name six differences that are perceived preattentively!',
      answer: '',
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/preatentivly.png'
  },
  {
      question: 'Name two differences that require attention!',
      answer: (
          <p>Parallelism, Juncture</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/attention.png'
  },
  {
      question: 'What distinguishes quantitative, nominal and ordinal data?',
      answer: (
          <p>Quantitative Data: Measured/simulated values represent numbers (land prices in different regions), Ordinal Data: There is an order, but values are not quantitative (e.g. days of the week, month), Nominal Data: no order, data represent categories (e.g. car manufacturers and countries)</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What is the perceivable range of light?',
      answer: (
          <p>380 nm - 750 nm</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Order the mapping techniques according to most accurate/effective to least accurate/effective!',
      answer: '',
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/orderedTechniques.png'
  },
  {
      question: 'What is Steven’s psychophysical power law?',
      answer: (
          <p>Empirical relationship between an increased intensity in a stimulus and the perceived increase</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/stevensLaw.png'
  },
  {
      question: 'What is 𝛾 for area and length? (Stevens Law)',
      answer: '',
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/stevensLawExp.png'
  },
  {
      question: 'State three diagram techniques for quantitative data!',
      answer: (
          <p>Bar chart, Pie chart, Stacked bar chart</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Explain how the horizon graphs are constructed!',
      answer: (
          <p>Divide the y-axis into equal intervals, Draw a line for each interval, Color the area between the line and the x-axis, Repeat for each interval, Stack the areas on top of each other</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/horizonGraphs.png'
  },
  {
      question: 'Name the advantages and disadvantages of stacked graphs and small multiples!',
      answer: '',
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/AdvDisStacked.png'
  },
  {
      question: 'Determine q1, q2, q3 of the values: 2,3,4,1,5,2,6,4,7,4,7,9,2,3,4,3,5,6,5,2,3,5',
      answer: (
          <p>q1 = 3, q2 = 4, q3 = 5</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Assign the scatter diagrams to the corresponding parallel coordinates!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/scatterToParallel.png'
  },
  {
      question: 'Explain star glyphs!',
      answer: (
          <>
              <p>Star glyphs are a visualization technique used to represent multivariate data. They consist of a central point or axis from which multiple axes extend outward, each representing a different variable. The length or position of each axis indicates the value of the corresponding variable, allowing for the simultaneous comparison of multiple attributes. Star glyphs are useful for visualizing complex datasets with many variables and can reveal patterns and relationships that might not be apparent in traditional charts or graphs. Star is composed of equally spaced spikes, originating from center, Length of spikes represents value of respective attribute, End of rays connected by line</p>
          </>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/starGlyphs.png'
  },
  {
      question: 'Determine the derivative!',
      answer: null,
      QuestionImageUrl: 'images/derivativQues.png',
      AnswerImageUrl: 'images/derivativAwn.png'
  },
  {
      question: 'How is the discrete forward and backward derivative in an image defined?',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/forwardBackward.png'
  },
  {
      question: 'What is the discrete gradient and how can it be calculated?',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/discreteGradient.png'
  },
  {
      question: 'What is an 𝜔 basin?',
      answer: (
          <p>Collection of all points where the water flows to the same local minimum</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null 
  },
  {
      question: 'Complete the Quadrangle Lemma:',
      answer: (
          <p>minimum, saddle, maximum, saddle</p>
      ),
      QuestionImageUrl: 'images/QuadrangleLemma.png',
      AnswerImageUrl: null
  },
  {
      question: 'Assign the correct words!',
      answer: null,
      QuestionImageUrl: 'images/eigenvalues.png',
      AnswerImageUrl: 'images/answerEigenvalues.png'
  },
  {
      question: 'Poincaré index: What is the index of this critical point?',
      answer: (
          <p>index = 1</p>
      ),
      QuestionImageUrl: 'images/poincareIndex.png',
      AnswerImageUrl: null
  },
  {
      question: 'What is the Laplace of this example?',
      answer: null,
      QuestionImageUrl: 'images/laplace.png',
      AnswerImageUrl: 'images/laplaceSolution.png'
  },
  {
      question: 'Sketch a regular and a rectilinear grid and show the difference with the picture!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/grids.png'
  },
  {
      question: 'Name an example where it makes sense to interpolate the data!',
      answer: (
          <p>Interpolation is useful when data points are missing or when a continuous representation of the data is required. For example, in weather forecasting, interpolation can be used to estimate temperature values at locations where measurements are not available. Similarly, in image processing, interpolation can be used to fill in missing pixels or create smoother transitions between neighboring pixels.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/interpolationExp.png'
  },
  {
      question: 'Given are three points, write down the equation system with the radial functions that interpolate the data! f(1) = 1 , f(3) = 2, f(4) = 0',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/equationSys.png'
  },
  {
      question: 'What are the drawbacks of radial basis functions?',
      answer: (
          <p>Every sample point has influence on whole domain; Adding a new sample requires re-solving the equation system; Computationally expensive (solving a system of linear equations)</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'When applying the edge flip algorithm, when do we have to flip an edge?',
      answer: (
          <p>When the edge is illegal, meaning the circumcircle of one of the incident triangles contains another vertex/point</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: null,
      answer: null,
      QuestionImageUrl: 'images/trianglePoints.png',
      AnswerImageUrl: 'images/trianglePointsAns.png'
  },
  {
      question: 'Determine 𝑓(0.6,0.8) with bilinear interpolation',
      answer: null,
      QuestionImageUrl: 'images/bilinearInterpolation.png',
      AnswerImageUrl: 'images/bilinearInterpolationAns.png'
  },
  {
      question: 'Handle the ambiguities in the cell (iso =0). Reason your decision.',
      answer: (
          <p>Calculate the isovalue in the middle by averaging all corners: (-3+6+4-5)/4 = 0.5. The isovalue in the middle is 0.5, which is greater than 0. Therefore, the middle point is inside the isosurface.</p>
      ),
      QuestionImageUrl: 'images/ambiguites.png',
      AnswerImageUrl: null
  },
  {
      question: 'Motivate 3 problems of Marching Cubes and provide a possible solution.',
      answer: (
          <p>Extra objects --> connected component analysis; Staircase artifacts --> mesh smoothing; Insufficient sampling at regions of high curvature --> adaptive Marching Cubes</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Describe 2 problems of standard Laplace smoothing and algorithms to handle them.',
      answer: (
          <p>Not volume preserving: LowPass filter/ Laplace+HC (Alternate between inward and outward moving of vertices); Features vanish (Detect features, adapt smoothing factor)</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Name two different weights of the discrete Laplace Beltrami operator',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/differentWeights.png'
  },
  {
      question: 'What is the idea behind “Distance aware smoothing”? Think of an example where it is crucial!',
      answer: (
          <p>No or less smoothing near important structures which are based on the distance to another structure; Tumor resection</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What are the input and output of “Transfer Functions”?',
      answer: (
          <p>input: data values (image intensities); output : color & opacity (RGBA); typically 1D, more complex 2D approaches exist as well; show/hide certain structures --> data exploration</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Explain “Pre --” and “Post Classification” as well as their effect on the resulting image quality!',
      answer: (
          <p>Pre Classification: Application of TF to all edge points in the filter range (result: RGBA quadruple), afterwards: (tri)linear interpolation of this quadruple; Post Classification : Interpolation of the intensity values from the data (e.g., Hounsfield Units), afterwards: application of transfer function to the interpolated result (pre integrated for quality enhancement)</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Schematically illustrate the basic “Ray Casting” procedure!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/rayCasting.png'
  },
  {
      question: 'Describe 2 techniques to increase the “ Ray Casting” performance!',
      answer: (
          <p>Early Ray Termination: Stop when full opacity is reached; Adaptive Sampling / Empty Space Skipping: Step size depends on image content, Low opacity, Only few contribution to pixel color, Increase step size and vice versa</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Explain the “ Maximum Intensity Projection (MIP)” and “ Closest Vessel Projection (CVP)”!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/MIP.png'
  },
  {
      question: 'What is P? P = I - nnT!',
      answer: (
          <p>P is an operator that projects every point on the tangent plane defined by the normal n</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Calculate the projection operator with 𝑛=(2,1,3)𝑇!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/projectionOperator.png'
  },
  {
      question: 'Determine the curvatures!',
      answer: null,
      QuestionImageUrl: 'images/curvature.png',
      AnswerImageUrl: 'images/curvatureSolution.png'
  },
  {
      question: 'Determine the isolines with value 0!',
      answer: null,
      QuestionImageUrl: 'images/isolines.png',
      AnswerImageUrl: 'images/isolinesSolution.png'
  },
  {
      question: 'Determine the covariant derivative!',
      answer: null,
      QuestionImageUrl: 'images/covariantDeriv.png',
      AnswerImageUrl: 'images/covariantDerivSol.png'
  },
  {
      question: 'What is the covariant derivative of a scalar field along isolines 25!',
      answer: (
          <p>0</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Compare two feature line techniques of your choice according to the order of derivatives, view dependencies, and features!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/featureLineTech.png'
  },
  {
      question: 'Given is the following vector field 𝒗(𝑥,𝑦,𝑧)=(𝑥−𝑦,𝑥^2−1,𝑥+𝑧)𝑇. Determine the Jacobian, the divergence, and the curl!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/jacobi.png'
  },
  {
      question: 'What is the difference between stream lines and path lines?',
      answer: (
          <p>Stream lines: Trajectories of massless particles in a “frozen” (steady) vector field, Trajectories of massless particles at one time step; Path lines: Trajectories of massless particles in (unsteady/time varying) flow, Follow one particle through time and space</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'Given is the vector field 𝒗(𝑥,𝑦)=(𝑥+𝑦,4𝑥−2𝑦), determine the critical point and classify it!',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/criticalPoint.png'
  },
  {
      question: 'Explain the algorithm for 2D LIC',
      answer: null,
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/2DLIC.png'
  },
  {
      question: 'State four interaction tasks',
      answer: (
          <p>Interaction Tasks: (lecture “Design and Interaction Principles”); Overview: gain overview of entire data; Zoom: zoom in information objects of; Filter: filter out uninteresting information; Details on demand: select object and get; Relate: view relationships between; History: keep history to allow undo; Extract: extract objects for further</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What is Shneiderman’s mantra?',
      answer: (
          <p>Shneiderman’s Mantra: Overview first, zoom and filter, then details on demand.</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What is the gauge chart?',
      answer: (
          <p>Visualization of a single number; Inspired by speedometer; High familiarity; Puts the number into context, i.e. the range of possible values; Critical values might be emphasized, similar to city speed limit at 50km/h; Criticism: Take up lots of space; Do not show history but only the current value</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: null
  },
  {
      question: 'What are Chernoff Faces?',
      answer: (
          <p>Up to 18 attributes can be mapped to face properties; Pro: humans are familiar with interpreting faces; Con: high variance in interpretation of the same face</p>
      ),
      QuestionImageUrl: null,
      AnswerImageUrl: 'images/ChernoffFace.png'
  }
];

export default questions;
